import React, {useEffect, useState} from 'react'
import {Col, Form, FormGroup, Row} from "reactstrap";
import Checkbox from "../../../atoms/Checkbox";
import styles from './CreatingAccountModal.module.scss'
import HorizontalLabelInputPair from "./HorizontalLabelInputPair";
import {UserRegistrationDataType} from "./types";
import Tooltip from "../../../atoms/Tooltip";
import addClassNames from "../../../classNameUtils";
import {ModalErrorAlert} from "../../../commons/errors";


type PersonalInformationFormProps = {
  state: UserRegistrationDataType,
  onChange: (action: { property: string, value: string | boolean }) => void,
  errorMessages: Error[] | string[] | null,
  handleSubmit: () => void,
  isFormComplete: boolean,
  badFields: string[]
}

export const registerFields = {
  name: 'Name',
  email: 'Email',
  phoneNumber: 'Phone',
  password: 'Password',
  repeatPassword: 'Confirm',
}

const passwordTip = <>
  Please add a strong password by<br/>
  making sure it consists of at least:<br/>
  <br/>
  • 8 characters<br/>
  • 1 number<br/>
  • 1 special character<br/>
  <br/>
  e.g. jWa8katy9A=4
</>;

function PersonalInformationForm({
                                   state,
                                   onChange,
                                   errorMessages,
                                   handleSubmit,
                                   isFormComplete,
                                   badFields
                                 }: PersonalInformationFormProps) {
  const [isFirstFieldOnFocus, setIsFirstFieldOnFocus] = useState(false);

  useEffect(() => {
    setIsFirstFieldOnFocus(true)
  }, []);

  return <Row className={"mx-0"}>
    <Col className={"px-0 " + styles.formContainer}>
      <div className={'mx-5'}>
        <Form onSubmit={handleSubmit} action={''}>
          <FormGroup>
            <HorizontalLabelInputPair labelClasses={styles.labelInputPersonal}
                                      inputClasses={addClassNames([
                                        {className: "ml-3 " + styles.nameField, condition: true},
                                        {
                                          className: styles.errorField,
                                          condition: Array.isArray(badFields) && !!badFields.find(field => field === registerFields.name)
                                        },
                                      ])}
                                      containerClasses={styles.labelInputContainer}
                                      name={registerFields.name}
                                      value={state.name}
                                      isRequired={true}
                                      autoComplete={false}
                                      onChange={value => onChange({property: "name", value: value})}
                                      setFocus={isFirstFieldOnFocus}
                                      onBlur={() => setIsFirstFieldOnFocus(false)}
                                      data-testid={"register-input-name"}/>
            <HorizontalLabelInputPair labelClasses={styles.labelInputPersonal}
                                      inputClasses={addClassNames([
                                        {className: "ml-3 " + styles.emailField, condition: true},
                                        {
                                          className: styles.errorField,
                                          condition: Array.isArray(badFields) && !!badFields.find(field => field === registerFields.email)
                                        },
                                      ])}
                                      containerClasses={styles.labelInputContainer}
                                      name={registerFields.email}
                                      value={state.email}
                                      isRequired={true}
                                      autoComplete={false}
                                      onChange={value => onChange({property: "email", value: value})}
                                      data-testid={"register-input-email"}/>
            <HorizontalLabelInputPair labelClasses={styles.labelInputPersonal}
                                      inputClasses={addClassNames([
                                        {className: "ml-3 " + styles.phoneNumberField, condition: true},
                                        {
                                          className: styles.errorField,
                                          condition: Array.isArray(badFields) && !!badFields.find(field => field === registerFields.phoneNumber)
                                        },
                                      ])}
                                      containerClasses={styles.labelInputContainer}
                                      name={registerFields.phoneNumber}
                                      value={state.phoneNumber}
                                      isRequired={true}
                                      autoComplete={false}
                                      onChange={value => onChange({property: "phoneNumber", value: value})}
                                      data-testid={"register-input-phone-number"}/>
            <div className={styles.separator}/>
            <div className={'d-flex flex-row'}>
              <HorizontalLabelInputPair labelClasses={styles.labelInputPersonal}
                                        inputClasses={addClassNames([
                                          {className: "ml-3 " + styles.passwordField, condition: true},
                                          {
                                            className: styles.errorField,
                                            condition: Array.isArray(badFields) && !!badFields.find(field => field === registerFields.password)
                                          },
                                        ])}
                                        containerClasses={styles.labelInputContainer}
                                        inputType={"password"}
                                        name={registerFields.password}
                                        value={state.password}
                                        placeholder={"e.g. jWa8katy9A=4"}
                                        isRequired={true}
                                        autoComplete={false}
                                        onChange={value => onChange({property: "password", value: value})}
                                        data-testid={"register-input-password"}/>
              <Tooltip text={passwordTip}
                       wrapperStyle={{marginLeft: '0.75rem', marginTop: '0.5rem'}}
                       mode={'help'}
                       arrow={false}
                       placement={"bottomRight"}>
                <i className={`fa-light fa-circle-question ${styles.tooltipIcon}`}/>
              </Tooltip>
            </div>
            <HorizontalLabelInputPair labelClasses={styles.labelInputPersonal}
                                      inputClasses={addClassNames([
                                        {className: "ml-3 " + styles.passwordField, condition: true},
                                        {
                                          className: styles.errorField,
                                          condition: Array.isArray(badFields) && !!badFields.find(field => field === registerFields.repeatPassword)
                                        },
                                      ])}
                                      containerClasses={styles.labelInputContainer}
                                      inputType={"password"}
                                      name={registerFields.repeatPassword}
                                      value={state.repeatPassword}
                                      placeholder={"Repeat password"}
                                      isRequired={true}
                                      autoComplete={false}
                                      onChange={value =>
                                        onChange({property: "repeatPassword", value: value})
                                      }
                                      data-testid={"register-input-repeat-password"}/>
            <div className={styles.separator}/>
            <Checkbox checked={state.checked}
                      className={styles.termsAndConditions}
                      label={<>
                        <span>I agree with the&nbsp;</span>
                        <a href={'https://vuuh.com/terms-conditions/'}
                           rel="noopener noreferrer"
                           target="_blank">
                          Terms & Conditions
                        </a>
                      </>}
                      onChange={() => {
                        onChange({property: "checked", value: !state.checked})
                      }}
                      data-testid={"register-checkbox-terms-and-conditions"}/>
          </FormGroup>
          <button className={'d-none'} type={'submit'} disabled={!isFormComplete}/>

          {!!errorMessages?.length && <>
            <div className={styles.separator}/>
            <ModalErrorAlert error={errorMessages[0]} className={"mb-3"}/>
          </>}
        </Form>
      </div>
    </Col>

  </Row>
}

export default PersonalInformationForm
