import React from "react";
import {useHistory} from "react-router";
import SuccessModalStep from "../../../dataDisplay/successModalStep/SuccessModalStep";
import successModalStyles from "../../../dataDisplay/successModalStep/SuccessModalStep.module.scss";

export default function ResetPasswordConfirmationModalContent({isTriggerConfirmation = true}) {
  const history = useHistory()
  const toggle = () => history.push('/login');

  return <SuccessModalStep
    title={isTriggerConfirmation ?
      "Check your email" :
      "Password changed"}
    subtitle={isTriggerConfirmation ?
      <p className={successModalStyles.subtitle}>
        Instructions for resetting your password have been sent <br/>
        to the provided email. Please make sure to check your <br/>
        <b>SPAM</b> folder in case you do not see an email.
      </p> :
      "You can now sign in with your new password."}
    toggle={toggle}
  />
}
